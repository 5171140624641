<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :to="{ path: '/' }"
      >
        <logo />
        <h2
          class="brand-text text-primary ml-1"
        >
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        xl="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold d-none d-lg-block"
          >
            <div>
              <h3>{{ appName }}</h3>
              {{ $t('about_the_project') }}
            </div>
          </b-card-title>

          <b-card-text class="mb-2">
            <small>{{ $t('auth.Please sign-in to your account') }}</small>
          </b-card-text>
          <login-form />
          <b-card-text class="text-center mt-2">
            <span>{{ $t('auth.New on our platform') }}? </span>
            <b-link :to="{name:'auth-register'}">
              <span>{{ $t('auth.Create an account') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>

import Logo from '@/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, VBTooltip, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import store from '@/store/index'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { $themeConfig } from '@themeConfig'

import LoginForm from './components/LoginForm.vue'
import { localize } from 'vee-validate'
import i18n from '@/libs/i18n'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCardTitle,
    BCardText,
    Logo,
    LoginForm,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Login',
    // all titles will be injected into this template
    titleTemplate: '%s | OneTimeJob',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'Login to your account',
        template: chunk => `${chunk} | OneTimeJob`,
        vmid: 'og:title',
      },
    ],
  },

  data() {
    return {
      sideImg: require('@/assets/images/pages/login.svg'),
    }
  },
  computed: {

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-dark.svg')
      }
      return this.sideImg
    },

  },

  setup() {
    const { appName } = $themeConfig.app
    localize(i18n.locale)
    return {
      appName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.img-fluid {
  filter: opacity(0.8);
}

</style>
